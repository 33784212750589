import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useActiveLink from '../../../hooks/useActiveLink';
import { isAuthorizedList } from '../../../utils/isAuthorisedSidebarItem';
import NavItem from './NavItem';
import { StyledPopover } from './styles';
export default function NavList({ data, depth, hasChild }) {
    const navRef = useRef(null);
    const { pathname } = useLocation();
    const { active, isExternalLink } = useActiveLink(data.path);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (open) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    useEffect(() => {
        const appBarEl = Array.from(document.querySelectorAll('.MuiAppBar-root'));
        // Reset styles when hover
        const styles = () => {
            document.body.style.overflow = '';
            document.body.style.padding = '';
            // Apply for Window
            appBarEl.forEach((elem) => {
                elem.style.padding = '';
            });
        };
        if (open) {
            styles();
        }
        else {
            styles();
        }
    }, [open]);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(NavItem, { ref: navRef, item: data, depth: depth, open: open, active: active, isExternalLink: isExternalLink, onMouseEnter: handleOpen, onMouseLeave: handleClose }), hasChild && (_jsx(StyledPopover, { open: open, anchorEl: navRef.current, anchorOrigin: { vertical: 'center', horizontal: 'right' }, transformOrigin: { vertical: 'center', horizontal: 'left' }, PaperProps: {
                    onMouseEnter: handleOpen,
                    onMouseLeave: handleClose,
                }, children: _jsx(NavSubList, { data: data.children, depth: depth }) }))] }));
}
function NavSubList({ data, depth }) {
    return (_jsx(_Fragment, { children: isAuthorizedList(data).map((list) => (_jsx(NavList, { data: list, depth: 1, hasChild: !!list.children }, list.title + list.path))) }));
}
